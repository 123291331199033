/*  eslint-disable */
import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import styled from "styled-components"

import { Footer } from "src/sections/landing"

import Enterprise from "../components/Enterprise"

const PageContainer = styled.div`
  height: auto;
`

const EnterprisePage = ({ ...props }) => (
  <PageContainer>
    <Helmet>
      <meta name="robots" content="all" />
      <title>AirMason's Enterprise-grade Employee Handbook Builder</title>
      <meta
        name="description"
        content="AirMason is an online tool that makes it easy for companies to get new hires up to speed quickly with an amazing employee handbook."
      />
    </Helmet>
    <Enterprise />
    <Footer />
  </PageContainer>
)

// const ContactPage = ({ ...props }) => (
//   <React.Fragment>
//     <Contact {...props} />
//   </React.Fragment>
// )

EnterprisePage.propTypes = {
  location: PropTypes.any.isRequired,
}

export default EnterprisePage
